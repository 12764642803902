import React, { useState } from "react";
import { useFetch } from "use-http";
import { StyleSheet } from "../utils/types";

export const Webcam = () => {
  const [showVideo, setShowVideo] = useState(true);
  const { loading, error, response } = useFetch(
    //"http://localhost:7600/weather-server/latest",
    "https://blaenavonweather.uk/weather-server/latest",
    {},
    []
  );

  let imageFilename = "";
  let imageTaken = "";
  if (!error && !loading) {
    imageFilename = response.data.imageFilename;
    imageTaken = response.data.imageTaken;
  }

  return (
    <div style={styles.container}>
      <img
        style={styles.cam}
        src={
          showVideo
            ? "https://geoclimatica.duckdns.org:8000/stream.mjpg"
            : imageFilename
        }
        alt="Current live weather in Blaenavon, Torfaen"
        onClick={() => setShowVideo(!showVideo)}
      />
      <div style={styles.footer}>
        <p style={styles.left} onClick={() => setShowVideo(!showVideo)}>
          {showVideo ? "" : "Click to watch live video!"}
        </p>
        <p style={showVideo ? styles.rightHighlight : styles.right}>
          {showVideo ? "Live Webcam" : imageTaken}
        </p>
      </div>
    </div>
  );
};

const styles: StyleSheet = {
  container: {
    padding: "10px",
  },
  cam: {
    width: "100%",
    cursor: "pointer",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    fontSize: "0.75rem",
    color: "#666",
    marginTop: "-0.5rem",
    justifyContent: "space-between",
  },
  left: {
    textTransform: "uppercase",
    color: "#ccc",
    cursor: "pointer",
  },
  right: {},
  rightHighlight: { color: "#ccc", textTransform: "uppercase" },
  blinking: {},
};
