import * as React from "react";
import { useWindowSize } from "../utils/useWindowSize";

export const Radar = () => {
  const { isMobile, width } = useWindowSize();
  return (
    <div>
      <iframe
        title="Rain Radar"
        src="https://maps.meteoradar.co.uk/GratisRadar/648/914/actueel?zoom=10"
        scrolling="no"
        width={isMobile ? width : 500}
        height={500}
        //frameborder="no"
      ></iframe>
    </div>
  );
};
