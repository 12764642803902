import * as React from "react";
import { StyleSheet } from "../utils/types";
import { useWindowSize } from "../utils/useWindowSize";

export const Flash = () => {
  const { isMobile } = useWindowSize();
  return (
    <div style={styles(isMobile).flash}>
      The highest Welsh weather cam south of Snowdonia
    </div>
  );
};

const styles = (isMobile: boolean): StyleSheet => {
  return {
    flash: {
      fontSize: isMobile ? "1rem" : "1.4rem",
      marginTop: isMobile ? "-0.5rem" : "-1rem",
      marginBottom: "1rem",
      fontStyle: "italic",
      color: "#ccc",
    },
  };
};
