import * as React from "react";

export const DavisWidget = () => {
  return (
    <div>
      {/* <iframe
        title="davis-widget"
        src="https://www.weatherlink.com/embeddablePage/show/6f7cfc1e45874597859c6ecfe22db5f1/signature"
        width="760"
        height="200"
      ></iframe> */}

      <iframe
        title="davis-widget-tall"
        src="https://www.weatherlink.com/embeddablePage/show/6f7cfc1e45874597859c6ecfe22db5f1/slim"
        width="338"
        height="500"
      ></iframe>

      <p style={{ textAlign: "center" }}>
        <em>
          <strong>NEW!</strong> Data live from our weather station <br />
          <a
            style={{ color: "white", fontWeight: "bold" }}
            href="https://www.weatherlink.com/embeddablePage/show/3c74c2ec6e744584b03e932d7ad95b9b/summary"
          >
            at Geoclimatica HQ
          </a>{" "}
          in Blaenavon.
        </em>
      </p>
    </div>
  );
};
