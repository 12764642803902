import React from "react";
import "./App.css";
import { Webcam } from "./components/Webcam";
import { Logo } from "./components/Logo";
import { DavisWidget } from "./components/DavisWidget";
import { Credits } from "./components/Credits";
import { Flash } from "./components/Flash";
import { TextContent } from "./components/TextContent";
import { Radar } from "./components/Radar";
import { Columns } from "./components/Columns";
import { EndTextContent } from "./components/EndTextContent";
import { WeatherWidget } from "./components/WeatherWidget";

//import { WeatherStats } from "./utils/types";

// const stats: WeatherStats = {
//   temp: "--",
//   conditions: "--",
//   windSpeed: "--",
//   windDirection: "--",
//   pressure: "--",
//   timestamp: "",
//   imageFilename: "",
// };

function App() {
  return (
    <div className="App">
      <div className="container">
        <Logo />
        <Flash />
        <Webcam />
        <TextContent />
        <Columns
          style={{
            marginTop: "30px",
            marginBottom: "30px",
          }}
          justifyContent="space-around"
        >
          <DavisWidget />
          <div style={{ width: "40px" }}></div>
          <Radar />
        </Columns>
        <EndTextContent />
        {/* <WeatherWidget /> */}
        <Credits />
      </div>
    </div>
  );
}

export default App;
