import React, { useState } from "react";
import { StyleSheet } from "../utils/types";

export const TextContent = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div style={styles.textContent}>
      <div dangerouslySetInnerHTML={expanded ? fullText() : briefText()} />
      <button style={styles.button} onClick={() => setExpanded(!expanded)}>
        {expanded ? "< Close" : "Read more >"}
      </button>
      <p style={{ clear: "both" }}></p>
    </div>
  );
};

const styles: StyleSheet = {
  textContent: {
    padding: "10px",
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    marginTop: "1rem",
    textAlign: "left",
    color: "#999",
  },
  button: {
    marginTop: "1rem",
    backgroundColor: "#4488D0",
    color: "white",
    border: "none",
    borderRadius: "0.5rem",
    fontSize: "1rem",
    padding: "0.5rem",
    cursor: "pointer",
    float: "right",
  },
  link: {
    color: "#ccc",
  },
};

const briefText = () => {
  return {
    __html: `<p>Welcome to Blaenavon Weather, where anyone with an interest in the current, past and future weather of Blaenavon can view the current conditions from
    Blaenavon and the Eastern Valley &ndash; wherever they are in the world &ndash;
    on camera live, using the highest altitude weather camera in Wales south of Snowdonia.</p>`,
  };
};
const fullText = () => {
  return {
    __html: `<p>Welcome to Blaenavon Weather, where anyone with an interest in the current, past and future weather of Blaenavon can view the current conditions from wherever they are in the world
 on camera live.</p>
  <p>That’s just the start &ndash; soon a continuously updating and comprehensive live feed weather station will upload continuous weather data: rainfall, 
  sunlight UV intensity etc. will be added; giving complete weather information for all those with an interest in the town &ndash; for locals or those planning a visit.</p>
  <p>You will also soon find an archive of weather photographs indicating the range of weather events in the recent past.</p>

 <p><a style="color: #ccc" href="https://www.facebook.com/BlaenavonTC/photos/a.349048432242847/701875620293458/?type=3">Community engagement &amp Blaenavon Intergenerational Group</a>:
 the aim here is to establish and enable 'folk' memories including old photographs, gardeners logs etc. to be collated and to offer community 
 get-togethers to learn about Blaenavon's changing weather in the context of climate change and how it is remembered or recorded.</p>

 <h4 style="color: #ccc">Photography &amp; the weather
<br>calling on Blaenavon photographers!</h4>
<p>To build a sense of community engagement in the weather and environment of Blaenavon we would like to ask for local photographers to regularly upload your photographs which 
represent our local area as this project expands we hope to form an annual weather photomosaic for each day of the year representing the weather of each year in Blaenavon.
Further details of this project will appear here soon.</p>`,
  };
};
