import * as React from "react";
import { StyleSheet } from "../utils/types";
import sun from "./sun.svg";
import { useWindowSize } from "../utils/useWindowSize";

export const Logo = () => {
  const { isMobile } = useWindowSize();
  return (
    <div style={styles(isMobile).logo}>
      <img
        style={styles(isMobile).sun}
        src={sun}
        className="App-logo"
        alt="sun"
      />
      <h1 style={styles(isMobile).title}>
        <strong>Blaenavon</strong>Weather
      </h1>
    </div>
  );
};

const styles = (isMobile: boolean): StyleSheet => {
  return {
    logo: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      marginTop: "1rem",
      marginBottom: "1rem",
      justifyContent: "center",
      alignItems: "center",
    },
    sun: {
      width: isMobile ? "2rem" : "4rem",
    },
    title: {
      margin: 0,
      paddingLeft: isMobile ? "0.5rem" : "1rem",
      color: "#4488D0",
      fontWeight: 200,
      fontSize: isMobile ? "1.5rem" : "3rem",
    },
  };
};
