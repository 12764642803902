import * as React from "react";

export type ColumnsProps = {
  style?: any;
  justifyContent?: string;
  children: any;
};
export const Columns = (props: ColumnsProps) => {
  return (
    <div
      style={{
        ...props.style,
        justifyContent: props.justifyContent,
        display: "flex",
      }}
    >
      {props.children}
    </div>
  );
};
