import React from "react";
import { StyleSheet } from "../utils/types";

export const EndTextContent = () => {
  return (
    <div style={styles.textContent}>
      <em>Blaenavon Weather</em> is an offshoot of{" "}
      <a style={styles.link} href="http://geoclimatica.com">
        geoclimatica.com
      </a>{" "}
      as it begins to grow as an outreach community science education in
      geology, environmental science &amp; climate resource.
    </div>
  );
};

const styles: StyleSheet = {
  textContent: {
    padding: "10px",
    fontSize: "1.2rem",
    lineHeight: "1.8rem",
    marginTop: "1rem",
    textAlign: "left",
    color: "#999",
  },
  link: {
    color: "#ccc",
  },
};
