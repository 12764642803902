import * as React from "react";
import leecorbin from "./leecorbin.svg";
import geoclimatica from "./geoclimatica-inline.svg";
import { StyleSheet } from "../utils/types";
import { useWindowSize } from "../utils/useWindowSize";

export const Credits = () => {
  const { isMobile } = useWindowSize();
  return (
    <div style={styles(isMobile).credits}>
      <span style={styles(isMobile).credit}>
        Brought to you by{" "}
        <a href="http://geoclimatica.com">
          <img
            style={styles(isMobile).logo}
            src={geoclimatica}
            alt="Geoclimatica"
          />
        </a>
      </span>
      <span
        style={{ ...styles(isMobile).credit, ...styles(isMobile).creditRight }}
      >
        Design by{" "}
        <a href="http://leecorbin.net">
          <img
            style={{
              ...styles(isMobile).logo,
              ...styles(isMobile).logoLeecorbin,
            }}
            src={leecorbin}
            alt="Lee Corbin"
          />
        </a>
      </span>
    </div>
  );
};

const styles = (isMobile: boolean): StyleSheet => {
  return {
    credits: {
      display: "flex",
      flexDirection: "row",
      marginTop: "2rem",
      marginBottom: "2rem",
      fontSize: isMobile ? 12 : 15,
      fontWeight: 300,
      alignItems: "flex-start",
      justifyContent: "center",
    },
    credit: {
      padding: isMobile ? 10 : 20,
    },
    creditRight: {
      borderLeft: "2px solid #333333",
    },
    logo: {
      marginTop: isMobile ? 10 : 0,
      display: "block",
      width: isMobile ? 120 : 200,
      position: "relative",
      paddingLeft: 10,
      paddingRight: 10,
    },
    logoLeecorbin: {
      top: 5,
      width: isMobile ? 100 : 150,
    },
  };
};
